import React from 'react'
import styled from 'styled-components';
import Container from '../layout/Container';
import Section from '../layout/Section';
import {SectionTitle, SectionHeader, P, Img} from '../elements';
import Contact from './Contact';
import Partners from '../Partners';
import {
  layout,
  colors,
  breakpoints,
} from '../../styles/_variables';
import {
  responsive_font,
  font_family,
} from '../../styles/_mixins';
// import Contact from './Contact';

const BGImg = styled.div.attrs({
  className: `w-full`
})`
  background-image: url(${p => p.image.childImageSharp.fluid.src});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  height: 60vw;
  @media screen and (min-width: ${breakpoints.md}) {
    height: 50vw;
  }
  @media screen and (min-width: ${breakpoints.md}) {
    height: 40vw;
  }
`;
const Body = styled.div.attrs({
  className: `lg:w-3/5 mt-10`
})`
  h1 {
    ${font_family};
    ${responsive_font('heading')};
  }
  p {
    ${font_family};
    line-height: 1.8em;
    ${responsive_font('body3')};
  }
`;
const Citizens = styled.div.attrs({
  className: `md:w-5/12 rounded-lg py-6 px-8 mt-8`
})`
  background-color: ${colors.bg_white};
`;
const Count = styled.span.attrs({
  className: `inline-block font-bold mb-8 mt-2`
})`
  ${responsive_font('huge')};
`;
const CountText = styled.p.attrs({
  className: `m-0 uppercase`
})`
  ${responsive_font('body3')};
`;
export default function Donate(props) {
  const {data, content} = props;

  return (
    <>
    <BGImg image={data.bgImage} />
    <Section>
      <Container>
        <SectionTitle>{data.title}</SectionTitle>
        <Body dangerouslySetInnerHTML={{__html: content}} />
        <Citizens>
          <Count>{data.citizens.count}</Count>
          <CountText>{data.citizens.text}</CountText>
        </Citizens>
      </Container>
    </Section>
    <Partners noButton />
    <Contact data={data.contact} />
    </>
  )
}
