import React from 'react'
import styled from 'styled-components';
import Container from '../layout/Container';
import Section from '../layout/Section';
import {SectionTitle, SectionHeader, P, A} from '../elements';
import ContactIllustration from '../svg/ContactIllustration';
import formatePhoneNumber from '../../utils/formatePhoneNumber';
import { colors, breakpoints } from '../../styles/_variables';

const IllustionWrp = styled.div.attrs({
  className: `absolute w-40`,
})`
  top: -5em;
  right: 0;
  @media screen and (min-width: ${breakpoints.md}) {
    top: 2em;
    right: 8%;
  }
`;

const Email = styled.div.attrs({
  className: ``
})`
  color: ${colors.text_black};
  a {
    color: ${colors.primary};
  }
`;

const Contact: React.FC = ({data}) => {
  return (
    <Section bg={colors.bg_secondary}>
      <Container>
        <SectionTitle>{data.title}</SectionTitle>
          <IllustionWrp>
            <ContactIllustration />
          </IllustionWrp>
        <div className="pt-16 md:pt-4" />
        <SectionHeader className="md:w-3/5 lg:w-1/3">{data.heading}</SectionHeader>
        <Email>
          Email us at <A href={`mailto:${data.email}`}><span></span>{data.email}</A>
        </Email>
      </Container>
    </Section>
  )
}

export default Contact;