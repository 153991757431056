import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import tw from "twin.macro";
import styled from "styled-components";
import Layout from "../components/PageLayout";
import Image from "../components/image";
import SEO from "../components/seo";
import About from '../components/about';
import FAQ from '../components/FAQ';
import {HeaderOffset} from '../components/elements';


const H1 = styled.h1.attrs({
  className: `bg-gray-200 `,
})`
  ${tw` py-4 flex`};
`;

const IndexPage = () => {
  const query = useStaticQuery(graphql`
    query aboutQuery {
      markdownRemark(frontmatter: {content: {eq: "about"}}) {
        html
        frontmatter {
          bgImage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          heading
          citizens {
            count
            text
          }
          contact {
            title
            heading
            email
          }
        }
      }
    }
  `);
  const data = query.markdownRemark.frontmatter;
  return (
    <Layout>
      <SEO title="About" />
      <HeaderOffset />
      <About data={data} content={query.markdownRemark.html} />
    </Layout>
  )
};

export default IndexPage;
